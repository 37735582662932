<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div >
    <div class="row">
      <div class="col-md-12">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0 bg-none"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <!-- Per Page -->
              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
              <b-col md="3">
                <div class="text-center">
                  <router-link :to="'/news/create/'">
                    <button
                      class="btn btn-primary btn-md"
                      style="margin-right: 5px"
                    >
                      Create
                    </button>
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Title</th>
                  <th class="text-center">Category</th>
                  <th class="text-center">image</th>
                  <th>Short Description</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(news, index) in newses.data"
                  :key="index"
                  v-if="newses.data.length"
                >
                  <td class="align-middle text-center">
                    {{ newses.meta.from + index }}
                  </td>
                  <td class="align-middle">
                    <p class="mb-0" style="font-size: medium">
                      <b>{{ news.title }}</b>
                    </p>

                    <p class="mb-0">
                      <b>Created By :</b> {{ news.created_by }}
                    </p>
                    <p class="mb-0">
                      <b>Created at :</b> {{ news.created_at }}
                    </p>
                    <p class="mb-0">
                      <span
                        class="badge"
                        style="margin-right: 5px"
                        :class="
                          news.status == 'Active'
                            ? 'badge-success'
                            : 'badge-danger'
                        "
                      >
                        {{ news.status }}
                      </span>
                      <span
                        class="badge"
                        :class="
                          news.is_show == 'Show'
                            ? 'badge-primary'
                            : 'badge-secondary'
                        "
                      >
                        {{ news.is_show }}
                      </span>
                    </p>
                  </td>
                  <td class="text-center">
                    {{ news.news_category }}
                  </td>
                  <td class="text-center">
                    <b-img
                      :src="
                        news.thumbnail ? news.thumbnail : getImage(200, 120)
                      "
                      width="200"
                      class="rounded text-center m-auto mb-md-0"
                    />
                  </td>
                  <td class="align-middle">
                    <div v-if="news.short_description.length < 250">
                      {{ news.short_description }}
                    </div>
                    <div v-else>
                      {{ news.short_description.substring(0, 250) + ".." }}
                    </div>
                  </td>

                  <td class="align-middle text-center">
                    <router-link :to="'/news/edit/' + news.id">
                      <button
                        class="btn btn-primary btn-sm"
                        style="margin-right: 5px"
                      >
                        <feather-icon icon="EditIcon" size="16" />
                      </button>
                    </router-link>
                    <button
                      v-if="permission.delete"
                      class="btn btn-danger btn-sm"
                      @click="Delete(news.id)"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ newses.meta.from ? newses.meta.from : 0 }} to
                  {{ newses.meta.to ? newses.meta.to : 0 }} of
                  {{ newses.meta.total ? newses.meta.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="newses"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import _ from "lodash";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      newses: [],
      permission: {
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/news/${queryParams}`)
        .then((res) => {
          this.newses = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    async Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/news/" + id)
            .then((response) => {
              this.s(response.data.message);
              this.getResults();
            })
            .catch((e) => {
              this.e(e.data.error);
            });
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "news.category.access") {
          this.permission.access = true;
        }

        if (permission.slug == "news.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "news.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
